import EmptyRouterView from '@/views/EmptyRouterView'
import TimelineView from '@/views/TimelineView'

import ProfileView from '@/views/ProfileView'
import PostView from '@/views/PostView'

import MarketplaceView from '@/views/MarketplaceView'
import MarketplaceSaleView from '@/views/marketplace/SaleView'
import MarketplaceAuctionView from '@/views/marketplace/AuctionView'
import MarketplaceProductView from '@/views/marketplace/ProductView'

import ChatDashboard from '@/components/chat/ChatDashboard'
import NotificationsView from '@/views/NotificationsView'
import SettingsView from '@/views/SettingsView'
import SearchView from '@/views/SearchView/SearchView.vue'
import WalletView from '@/views/WalletView'
import WalletEventList from '@/components/wallet/WalletEventList'
import WithdrawTable from '@/components/wallet/WithdrawTable'
import ReferralView from '@/views/ReferralView'
import TrialView from '@/components/subscriptions/views/ClaimTrialView'
// Sidebars

import UserSubscribers from '@/components/users/UserSubscribers'
import Recommendations from '@/components/widgets/Recommendations'
import Application from '@/components/layouts/Application'

import PartnerPayments from '@/components/agents/PartnerPayments'
import PartnersTable from '@/components/agents/PartnersTable'

const AdminUsersView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/UsersView.vue')

const AdminUserView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/UserView.vue')

const StarsView = () => import(/* webpackChunkName: "group-stars" */'./../../views/stars/StarsView.vue')
const StarsDiscoveryView = () => import(/* webpackChunkName: "group-stars" */'./../../views/stars/StarsDiscoveryView.vue')

const FavoritesView = () => import(/* webpackChunkName: "group-favorites" */'./../../views/FavoritesView.vue')
const FavoritesPurchasedView = () => import(/* webpackChunkName: "group-favorites" */'./../../views/favorites/PurchasedView.vue')
const FavoritesSavedView = () => import(/* webpackChunkName: "group-favorites" */'./../../views/favorites/SavedView.vue')

const MedialibView = () => import(/* webpackChunkName: "group-medalib" */'./../../views/VideotubeView.vue')
const MedialibTrendingView = () => import(/* webpackChunkName: "group-medalib" */'./../../views/medialib/TrendingView.vue')
const MedialibInterestView = () => import(/* webpackChunkName: "group-medalib" */'./../../views/medialib/InterestView.vue')
const MedialibRecentView = () => import(/* webpackChunkName: "group-medalib" */'./../../views/medialib/RecentView.vue')

const LivesView = () => import(/* webpackChunkName: "group-lives" */'./../../views/lives/LivesView.vue')
const ConferenceView = () => import(/* webpackChunkName: "group-lives" */'./../../views/lives/ConferenceView.vue')

const StatisticsView = () => import(/* webpackChunkName: "group-statistics" */'./../../views/StatisticsView.vue')
const RankingUsersView = () => import(/* webpackChunkName: "group-statistics" */'./../../views/statistics/RankingUsersView.vue')
const RankingPostsView = () => import(/* webpackChunkName: "group-statistics" */'./../../views/statistics/RankingPostsView.vue')

const PartnerListView = () => import(/* webpackChunkName: "group-agents" */'./../../views/PartnerListView.vue')
const PartnerView = () => import(/* webpackChunkName: "group-agents" */'./../../views/PartnerView.vue')

const AdminComplaintsView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/ComplaintsView.vue')

const ValidateView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/validate/ValidateView.vue')
const PostsValidateView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/validate/posts/PostsValidateView')
const PostsPendingView = () => import(/* webpackChunkName: "group-admin" */ '../../views/admin/validate/posts/PostsPendingView.vue')
const PostsReviewedView = () => import(/* webpackChunkName: "group-admin" */ '../../views/admin/validate/posts/PostsReviewedView.vue')
const ProductsValidateView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/validate/products/ProductsValidateView')
const ProductsPendingView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/validate/products/ProductsPendingView.vue')
const ProductsReviewedView = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/validate/products/ProductsReviewedView.vue')

const PostPromo = () => import(/* webpackChunkName: "group-admin" */ './../../views/admin/posts/PostPromo.vue')

const ResourcesView = () => import('./../../views/resources/ResourcesView.vue')

const routes = [
  {
    path: '/home',
    name: 'home',
    components: {
      default: TimelineView,
      sidebar: Recommendations
    },
    meta: {
      authenticated: true,
      sidebar: true,
      cache: true
    }
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView,
    props: (route) => ({ search: route.query.search }),
    meta: { authenticated: true }
  },
  {
    path: '/resources',
    name: 'resources',
    component: ResourcesView,
    props: true,
    meta: {
      layout_set: true,
      layout: Application,
      cache: true
    }
  },
  {
    path: '/resources/:tab',
    name: 'resources_section',
    component: ResourcesView,
    props: true,
    meta: {
      layout_set: true,
      layout: Application,
      cache: true
    }
  },
  {
    path: '/controller/promo',
    name: 'post_promo_controller',
    component: PostPromo,
    meta: { authenticated: true }
  },
  {
    path: '/stars',
    components: {
      default: EmptyRouterView,
      sidebar: Recommendations
    },
    meta: {
      layout_set: true,
      layout: Application,
      sidebar: true
    },
    children: [{
      path: '',
      name: 'stars',
      component: StarsView
    }, {
      path: 'discover',
      name: 'stars_discover',
      props: { discover: true },
      component: StarsDiscoveryView,
      meta: {
        sidebar: false,
        cache: true
      }
    }, {
      path: 'fans',
      name: 'stars_subscribers',
      props: { discover: true },
      component: UserSubscribers
    }]
  },
  {
    path: '/posts/:uuid',
    name: 'post',
    component: PostView,
    props: true,
    meta: {
      layout_set: true,
      layout: Application
    }
  },
  {
    path: '/favorites',
    component: EmptyRouterView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'favorites',
      component: FavoritesView
    }, {
      path: 'purchased',
      name: 'favorites_purchased',
      component: FavoritesPurchasedView
    }, {
      path: 'saved',
      name: 'favorites_saved',
      component: FavoritesSavedView
    }]
  },
  {
    path: '/lives',
    component: EmptyRouterView,
    meta: {
      layout_set: true,
      layout: Application
    },
    children: [{
      path: '',
      name: 'conferences',
      component: LivesView
    }, {
      path: ':uuid',
      name: 'conference',
      props: true,
      component: ConferenceView
    }]
  },
  {
    path: '/videotube',
    component: EmptyRouterView,
    meta: {
      layout_set: true,
      layout: Application
    },
    children: [{
      path: '',
      name: 'medialib',
      component: MedialibView
    }, {
      path: 'recently_added',
      name: 'medialib_recently_added',
      component: MedialibRecentView,
      meta: { cache: true }
    }, {
      path: 'trending',
      name: 'medialib_trending',
      component: MedialibTrendingView,
      meta: { cache: true }
    }, {
      path: 'interests',
      name: 'medialib_interests',
      component: MedialibInterestView,
      meta: { cache: true }
    }]
  },
  {
    path: '/controller/validation',
    component: EmptyRouterView,
    meta: { authenticated: true },
    children: [
      {
        path: '',
        name: 'controller_validation',
        component: ValidateView
      }, {
        path: 'posts',
        name: 'posts_validation',
        component: EmptyRouterView,
        children: [
          {
            path: 'pending',
            name: 'post_validate_pending',
            component: PostsPendingView
          }, {
            path: 'reviewed',
            name: 'post_validate_reviewed',
            component: PostsReviewedView
          }
        ]
      }, {
        path: 'products',
        name: 'products_validation',
        component: EmptyRouterView,
        children: [
          {
            path: 'pending',
            name: 'products_validate_pending',
            component: ProductsPendingView
          }, {
            path: 'reviewed',
            name: 'products_validate_reviewed',
            component: ProductsReviewedView
          }
        ]
      }
    ]
  },
  {
    path: '/products',
    component: EmptyRouterView,
    meta: {
      layout_set: true,
      layout: Application
    },
    children: [{
      path: '',
      alias: '/products',
      name: 'marketplace',
      component: MarketplaceView,
      meta: { cache: true }
    }, {
      path: 'sales',
      name: 'marketplace_sales',
      component: MarketplaceSaleView,
      meta: { cache: true }
    }, {
      path: 'auctions',
      name: 'marketplace_auctions',
      component: MarketplaceAuctionView,
      meta: { cache: true }
    }, {
      path: ':uuid',
      alias: '/products/:uuid',
      name: 'product',
      props: true,
      component: MarketplaceProductView
    }]
  },
  {
    path: '/messages',
    name: 'messages',
    component: ChatDashboard,
    props: true,
    meta: { authenticated: true }
  },
  {
    path: '/notifications',
    name: 'notifications',
    components: { default: NotificationsView },
    meta: {
      authenticated: true,
      sidebar: true
    }
  },
  {
    path: '/statistics',
    component: EmptyRouterView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'statistics',
      component: StatisticsView
    }, {
      path: 'users',
      name: 'statistics_users',
      component: RankingUsersView
    }, {
      path: 'posts',
      name: 'statistics_posts',
      component: RankingPostsView
    }]
  },
  {
    path: '/complaints',
    name: 'complaints',
    component: AdminComplaintsView,
    meta: { authenticated: true }
  },
  {
    path: '/wallet',
    component: WalletView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'wallet',
      component: WalletEventList
    }, {
      path: 'withdraws',
      name: 'wallet_withdraws',
      component: WithdrawTable
    }]
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: {
      authenticated: true,
      sidebar: true
    }
  },
  {
    path: '/referrals',
    name: 'referrals',
    component: ReferralView,
    meta: { authenticated: true }
  },
  {
    path: '/trials/:token',
    name: 'trial',
    component: TrialView,
    props: true,
    meta: {
      layout_set: true,
      layout: Application
    }
  },
  {
    path: '/agent/users',
    component: EmptyRouterView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'agent_users',
      component: AdminUsersView
    }, {
      path: ':username',
      name: 'agent_user',
      props: true,
      component: AdminUserView
    }]
  },
  {
    path: '/partners',
    component: EmptyRouterView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'partners',
      component: PartnerListView
    }, {
      path: '/view/:username',
      name: 'partner_view',
      props: true,
      component: AdminUserView
    }, {
      path: '/billing',
      name: 'partner_billing',
      props: true,
      component: PartnerPayments
    }, {
      path: '/agents',
      name: 'partner_partners',
      props: true,
      component: PartnersTable
    }, {
      path: ':partner',
      name: 'partner',
      props: true,
      component: PartnerView
    }]
  },
  {
    path: '/:username',
    name: 'influencer',
    component: ProfileView,
    props: true,
    meta: {
      layout_set: true,
      layout: Application,
      cache: true
    }
  },
  {
    path: '/:username/:tab',
    name: 'profile_section',
    component: ProfileView,
    props: true,
    meta: {
      layout_set: true,
      layout: Application,
      cache: true
    }
  },
  {
    path: '/controller/users',
    component: EmptyRouterView,
    meta: { authenticated: true },
    children: [{
      path: '',
      name: 'controller_users',
      component: AdminUsersView
    }, {
      path: ':username',
      name: 'controller_user',
      props: true,
      component: AdminUserView
    }]
  }
]

export default routes
export const AuthenticatedRouteNames = [
  'resources',
  'resources_section',
  'referrals',
  'post_promo_controller',
  'stars',
  'stars_discover',
  'stars_subscribers',
  'favorites',
  'favorites_purchased',
  'favorites_saved',
  'conferences',
  'conference',
  'medialib',
  'medialib_recently_added',
  'medialib_trending',
  'medialib_interests',
  'controller_validation',
  'posts_validation',
  'post_validate_pending',
  'post_validate_reviewed',
  'products_validation',
  'products_validate_pending',
  'products_validate_reviewed',
  'marketplace',
  'marketplace_sales',
  'marketplace_auctions',
  'product',
  'messages',
  'notifications',
  'statistics',
  'statistics_users',
  'statistics_posts',
  'complaints',
  'wallet',
  'wallet_withdraws',
  'settings',
  'trial',
  'agent_users',
  'partners',
  'partner_view',
  'partner_billing',
  'partner_partners',
  'partner',
  'agent_user',
  'controller_users',
  'controller_user',
]
