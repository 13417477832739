<template>
  <div>
    <template v-if="navbar">

      <c-btn rounded text
             :fab="icon"
             small
             :color="color"
             @click.stop="checkRole">
        <v-icon>add_to_queue</v-icon>
        <!--        <span v-if="!icon" :class="resizeBtn">{{ $t('actions.publish') }}</span>-->
      </c-btn>
    </template>
    <template v-else-if="card">
      <div class="launcher">
        <v-row no-gutters align="center"
               class="pa-2 bordered rounded">
          <v-col cols="12" lg="4" class="flex-grow-1">
            <v-avatar size="48">
              <c-img placeholder="false" height="48" :src="currentUser.avatarS3Route"></c-img>
            </v-avatar>
            <span class="h2">{{ $t('publication.select_type') }}</span>
          </v-col>
          <v-col cols="12" lg="8" class="actions">
            <c-btn outlined :color="color"
                   @click.stop="onSelectedType('post')">
              <v-icon>add_a_photo</v-icon>
              <span v-if="!isMobile"> {{ $t("publication.type_post") }}</span>
            </c-btn>
            <c-btn outlined
                   :color="color"
                   @click.stop="onSelectedType('product')">
              <v-icon>shopping_basket</v-icon>
              <span v-if="!isMobile"> {{ $t("publication.type_product") }} </span>
            </c-btn>
            <c-btn outlined
                   :color="color"
                   @click.stop="onSelectedType('conference')">
              <v-icon>radio_button_checked</v-icon>
              <span v-if="!isMobile"> {{ $t("publication.type_conference") }} </span>
            </c-btn>
            <PromoBtn :selected="selectedType === 'promo'" color="primary"
                      @promoPost="onSelectedType('promo')"></PromoBtn>
          </v-col>
        </v-row>
      </div>
    </template>

    <c-btn v-else
           rounded depressed
           :fab="icon"
           :small="icon && small"
           :color="color"
           @click.stop="checkRole">
      <v-icon>add</v-icon>
      <span v-if="!icon" :class="resizeBtn">{{ $t('actions.publish') }}</span>
    </c-btn>
    <warning
      :closable="false"
      :actions="false"
      :title="$t('application.contract.title')"
      :body="$t('application.contract.textModals')"
      v-model="showModal2Contract">
      <div style="display: flex;justify-content: center">
        <c-btn color="secondary" class="mr-2" @click="showModal2Contract=false">
          {{ $t('application.contract.btnClose') }}
        </c-btn>
        <c-btn class="ml-2" to="/contract">
          {{ $t('application.contract.btnSign') }}
        </c-btn>
      </div>
    </warning>
    <PublicationDialog
      v-model="dialog"
      :type.sync="selectedType"/>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import dialog from '@/components/mixins/dialog'
import PublicationDialog from '@/components/custom/PublicationDialog'
import PromoBtn from '@/components/custom/PromoBtn'
import project from '@/project'

export default {
  name: 'PublicationLauncher',
  mixins: [dialog],
  components: {PublicationDialog, PromoBtn},
  props: {
    button: Boolean,
    card: Boolean,
    icon: Boolean,
    navbar: {type: Boolean, default: false},
    small: {type: Boolean, default: true}
  },
  data() {
    return {
      selectedType: 'post',
      showModal2Contract: false,
      project
    }
  },
  computed: {
    ...mapState('session', ['authenticated']),
    ...mapState('application', ['isMobile']),
    ...mapState('preferences', ['darkTheme']),
    ...mapState('profile', ['currentUser', 'isInfluencer']),
    color() {
      return this.darkTheme ? 'secondary' : 'primary'
    },
    resizeBtn() {
      return {
        resizeBtn: this.$i18n.locale === 'de'
      }
    }
  },
  methods: {
    onSelectedType(type) {
      this.selectedType = type
      this.dialog = true
    },

    checkRole() {
      if (!this.authenticated) {
        sessionStorage.setItem('origin', this.$router.currentRoute.fullPath)
        this.$router.push({name: 'login'})
      } else if (!this.isInfluencer) {
        this.$router.push({name: 'influencer', params: {username: this.currentUser.username}})
      } else {
        /*if (!this.currentUser.signedDocument) {
          this.showModal2Contract = true
          return
        }*/
        this.showDialog()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.launcher {
  padding: 12px;

  .actions {
    padding: 0px;

    ::v-deep > * {
      margin: 3px;
    }
  }
}

.resizeBtn {
  font-size: xx-small;
}
</style>
