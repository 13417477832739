<template>
  <c-btn
    v-bind="$attrs"
    :color="color"
    :hover-color="hoverColor"
    @click.stop.prevent="emitAction">
    {{ buttonText }}
    <slot />
  </c-btn>
</template>
<script>
import config from "@/project";

export default {
  name: 'PurchasetButton',
  props: {
    resource: Object,
    color: String,
    hoverColor: String,
    label: String
  },
  computed: {
    buttonText () {
      const { price, currency } = this.resource
      const localePrice = this.$currency(price * (this.isSpecialOfferActive ? (100 - config.specialOffer.discount) / 100 : 1), currency)
      return this.label || this.$t('posts.card_overlay.buy_action', { price: localePrice })
    },
    isSpecialOfferActive () {
      const today = new Date()
      return config.specialOffer.active && config.specialOffer.startDate < today && config.specialOffer.endDate > today && config.specialOffer.discount > 0
    }
  },

  methods: {
    emitAction () {
      this.$emit('click', true)
    }
  }

}
</script>
