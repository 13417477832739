<template>
  <v-card flat tile
          class="pa-2"
          :class="{ 'recommended-post': post.recommended, dark: darkTheme, marginMobile: isMobile }"
          v-bind="$attrs">
    <PostRecommended v-if="post.recommended"
                     class="recommended-row"/>
    <PostAuthor :post="post"
                @edit="showDialog"/>
    <div class="media-wrapper"
         :style="mediaWrapperStyle">
      <PostDraft large v-if="post.draft"/>
      <Resource v-else-if="showFake"
                contain preview fake-video
                :hover.sync="hover"
                :username="post"
                :resource="post.preview">
        <FakePostOverlay :post="post"
                         :hover="hover"
                         class="resource-content"/>
      </Resource>
      <Swiper v-else-if="post.visible"
              preview-height="500"
              :preview="!post.hasGifs"
              :carousel="post.visible && isImage"
              :resources="post.resources"
              :video-options="videoOptions"
              :username="post"
              @videoEnded="showVideoEvent">
      </Swiper>
      <Resource v-else
                contain preview
                rounded :nocontrols="!hasTeaser"
                max-height="500"
                :hover.sync="hover"
                :autoplay="!hasTeaser && hover"
                :resource="post.preview"
                :username="post"
                :loop="false"
                @videoEnded="showVideoEvent">
        <component v-bind:is="resourceOverlayComponent"
                   v-show="showResourceOverlay"
                   :user="post.author"
                   :hover="hover"
                   :post="post"
                   class="resource-content"/>
      </Resource>
      <component v-if="showCardOverlay"
                 class="media-overlay"
                 :is="cardOverlayComponent"
                 :user="post.author"
                 :post="post"/>
    </div>
    <div v-if="mentions.length" class="d-flex">
      <span class="mr-2 small-text">{{ $t('words.featured') }}</span>
      <router-link v-for="mention in mentions"
                   :key="`mention-${mention.username}`"
                   class="mr-1 small-text"
                   :to="{ name: 'influencer', params: { username: mention.username } }"
                   @click.native="addSpam4Spam(mention.username)">
        @{{ mention.username }}
      </router-link>
    </div>
    <component v-if="!post.blockedCountry" v-bind:is="showContent"/>

    <PostActions :post="post"
                 @click:comments="switchComments"/>

    <c-dialog v-model="dialog"
              :actions="false">
      <template slot="title">
        <h1>{{ $t('posts.edit.title') }}</h1>
      </template>
      <EditPost
        :key="`editpost-${post.id}-${dialog}`"
        :post="post"
        @close="hideDialog"
      />
    </c-dialog>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { RevolutionActions } from '@/store'
import dialog from '@/components/mixins/dialog'
import payments from '@/components/mixins/payments'
import PostActions from '@/components/posts/PostActions'
import PostAuthor from '@/components/posts/PostAuthor'
import PostDraft from '@/components/posts/PostDraft'
import PostNoVisible from '@/components/posts/PostNoVisible'
import PostRecommended from '@/components/posts/PostRecommended'
import EditPost from '@/components/posts/EditForm'
import Resource from '@/components/posts/Resource'
import SubscribeOverlay from './overlays/SubscribeOverlay.vue'
import PurchaseOverlay from './overlays/PurchaseOverlay.vue'
import RegisterOverlay from './overlays/RegisterOverlay.vue'
import FakePostOverlay from './FakePostOverlay.vue'
import project from '@/project'

import { replaceMention } from '@/components/scripts/ReplaceMentions.js'

export default {
  name: 'PostPage',
  mixins: [dialog, payments],
  components: {
    FakePostOverlay,
    RegisterOverlay,
    SubscribeOverlay,
    PurchaseOverlay,
    Resource,
    PostAuthor,
    PostDraft,
    PostActions,
    PostRecommended,
    PostNoVisible,
    EditPost
  },
  props: {
    post: {
      type: Object,
      default: () => ({})
    },
    fakeVideo: { type: Boolean }
  },

  data () {
    return {
      hover: false,
      videoFinished: false
    }
  },

  computed: {
    ...mapState('session', ['authenticated', 'user', 'star']),
    ...mapState('application', ['isMobile']),
    ...mapState('preferences', ['darkTheme']),
    ...mapState('profile', ['currentUser']),
    showFake () {
      return this.isVideo && this.fakeVideo && (this.post.visible || this.hasTeaser || this.isPremium)
    },
    hasTeaser () {
      return this.post.preview.teaser
    },
    isGifTeaser () {
      const url = this.post.preview.url.split('?')[0]
      return this.hasTeaser && url.endsWith('.gif')
    },
    showCardOverlay () {
      return this.videoFinished && (!this.authenticated || (!this.post.visible && this.hasTeaser))
    },
    showResourceOverlay () {
      return (!this.hasTeaser || this.isGifTeaser) && this.hover
    },
    cardOverlayComponent () {
      return !this.authenticated ? 'RegisterOverlay'
        : this.isPremium ? 'PurchaseOverlay'
          : this.isSubscription && this.isCreditCardEnabled ? 'SubscribeOverlay'
            : ''
    },
    resourceOverlayComponent () {
      return this.isImage || !this.hasTeaser || this.isGifTeaser ? 'PostNoVisible' : ''
    },
    mediaWrapperStyle () {
      return this.showCardOverlay && !this.authenticated && this.isMobile ? { 'min-height': '600px' }
        : ''
    },
    isImage () {
      return this.post.postType === 'image'
    },
    isVideo () {
      return !this.isImage
    },

    isOpen () {
      return this.post.visibility === 'open'
    },

    isSubscription () {
      return this.post.visibility === 'followers'
    },

    isPremium () {
      return this.post.visibility === 'premium'
    },

    videoOptions () {
      if (this.isImage) {
        return {}
      }

      return {
        autoplay: this.hover && !this.post.visible,
        loop: false,
        nocontrols: !this.post.visible
      }
    },

    decodedContent () {
      try {
        return decodeURI(this.post.content)
      } catch (err) {
        return this.post.content
      }
    },

    showContent () {
      const comment = replaceMention(this.decodedContent, project.project, this.post.author.username)
      return { template: `<div class="my-4">${comment}</div>` }
    },
    mentions () {
      return this.post.mentions || []
    }
  },

  methods: {
    showVideoEvent () {
      this.videoFinished = true
    },
    switchComments () {
      this.$emit('switch-comments')
    },
    async addSpam4Spam (username) {
      await this.$store.dispatch(RevolutionActions.AddCreator, {
        affiliate: this.post.username,
        username,
        source: project.project
      })
    }
  }

}
</script>
<style lang="scss" scoped>
@use 'src/scss/colors';

.media-wrapper {
  position: relative;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .media-overlay {
    display: table-cell;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

.recommended-post {
  background-color: colors.$light-pink !important;

  &.dark {
    background-color: colors.$light-dark-theme-background !important;
  }

  .recommended-row {
    margin-top: -8px;
    margin-bottom: 16px;
  }
}

.marginMobile {
  margin-bottom: 50px !important;
}

</style>
