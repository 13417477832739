<template>
  <c-btn :outlined="!selected" :color="color" :style="{maxHeight: isDialog ? '28px' : '34px'}"
          @click.stop="emitPromo()" v-if="promoAvailable">
    <v-icon>theaters</v-icon>
    <span > {{ $t("publication.type_promo") }} </span>
  </c-btn>
</template>

<script>
import { mapState } from 'vuex'
import project from '@/project'

export default {
  name: 'PromoBtn',
  data () {
    return {
      project
    }
  },
  props: {
    color: { type: String, default: 'primary' },
    selected: Boolean,
    isDialog: Boolean
  },
  computed: {
    ...mapState('application', ['isMobile']),
    ...mapState('profile', ['currentUser']),
    promoAvailable () {
      return true
      //return this.eventStarted(new Date()) && !this.eventEnded(new Date()) && this.project.campaign.post
    }
  },
  methods: {

    eventStarted (today) {
      // LOS MESES SON DE 0 - 11)
      const users = this.project.campaign.users
      let activeUser = true
      if (users.length > 0) {
        activeUser = false
        const index = users.findIndex(u => u === this.currentUser.username)
        activeUser = index !== -1
      }
      const eventStarted = this.project.campaign.started
      const activeDate = today - eventStarted > 0
      return activeDate && activeUser
    },
    eventEnded (today) {
      // LOS MESES SON DE 0 - 11)

      var eventEnded = this.project.campaign.ended
      return eventEnded - today < 0
    },
    emitPromo () {
      this.$emit('promoPost')
    }
  },
  mounted () {
    const sp = this.$route.query.sp
    if (sp === 'promo') {
      this.$emit('promoPost')
    }
  }
}
</script>

<style scoped>

</style>
