<template>
  <section>
    <c-dialog
      v-model="dialog"
      :actions="false">
      <template #title>
        <h1>{{ $t('withdraws.withdraw_methods.title') }}</h1>
      </template>
      <template v-if="!method">
        <span>{{ $t('withdraws.withdraw_methods.chose_description') }}</span>
        <v-select v-if="!method"
                  outlined
                  color="primary"
                  :items="availableMethods"
                  style="height: 68px;"
                  v-model="selectedMethod"/>
      </template>
      <component v-if="selectedMethod"
                 :is="methodInfo"
      />
      <component v-if="selectedMethod"
                 :is="methodForm"
                 :method="method"
                 @methodAttrs="saveMethodAttrs"
                 @validForm="saveValidForm"/>

      <v-form v-model="validFormDialog" v-if="selectedMethod" class="p-0 m-0">
        <v-row dense align="center" v-if="requiredFile">
          <v-col cols="8">
            {{ $t('withdraws.withdraw_methods.file_uploaded') }}
            <v-btn
              css="ma-1"
              v-if="isEdit && method.withdrawMethodUrl"
              icon :href="method.withdrawMethodUrl" download>
              <v-icon>file_download</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-icon v-if="isUploadedAgreement" color="success" right>check_circle</v-icon>
          </v-col>
          <v-col cols="3">
            <FileInput
              button autoupload small
              style="justify-content: flex-end"
              max-files="1"
              content-type="*"
              :loading="(file || {}).loading"
              @file-added="file = $event"
              :rules="fileRequired"
            > {{ isEdit && method.withdrawMethodUrl ? $t('actions.change') : $t('actions.upload') }}
            </FileInput>
          </v-col>
        </v-row>
        <v-row dense align="left" >
          <v-checkbox v-model="withdrawMethodApproved"
                      :rules="[$vRequired]"
                      :label="$t('models.user.payment_person.cert_identity')"/>
        </v-row>
      </v-form>
      <v-row justify="end" v-if="selectedMethod">
        <v-col cols="auto">
          <c-btn
            text color="secondary"
            @click="hideDialog"
          >
            {{ $t('actions.cancel') }}
          </c-btn>
        </v-col>
        <v-col cols="auto">
          <c-btn
            :disabled="!validFromSave"
            @click="onSubmit"
          >
            {{ $t('actions.save') }}
          </c-btn>
        </v-col>
      </v-row>
    </c-dialog>
    <!--<PaymentWarning v-if="showWarning" showContinue @continue="confirmSubmit()"/>-->
  </section>

</template>

<script>
import { mapState } from 'vuex'
import dialog from '@/components/mixins/dialog'
import BankMethodForm from '@/components/withdraws/forms/BankMethodForm'
import BtcMethodForm from '@/components/withdraws/forms/BtcMethodForm'
import CosmoMethodForm from '@/components/withdraws/forms/CosmoMethodForm'
import EPayMethodForm from '@/components/withdraws/forms/EPayMethodForm'
import PaxumMethodForm from '@/components/withdraws/forms/PaxumMethodForm'
import PaypalMethodForm from '@/components/withdraws/forms/PaypalMethodForm'
import SkrillMethodForm from '@/components/withdraws/forms/SkrillMethodForm'
import TransferWiseMethodForm from '@/components/withdraws/forms/TransferWiseMethodForm'
import WesternMethodForm from '@/components/withdraws/forms/WesternMethodForm'
import BankMethodInfo from '@/components/withdraws/infos/BankMethodInfo'
import BtcMethodInfo from '@/components/withdraws/infos/BtcMethodInfo'
import CosmoMethodInfo from '@/components/withdraws/infos/CosmoMethodInfo'
import EPayMethodInfo from '@/components/withdraws/infos/EPayMethodInfo'
import PaxumMethodInfo from '@/components/withdraws/infos/PaxumMethodInfo'
import PaypalMethodInfo from '@/components/withdraws/infos/PaypalMethodInfo'
import SkrillMethodInfo from '@/components/withdraws/infos/SkrillMethodInfo'
import TransferWiseMethodInfo from '@/components/withdraws/infos/TransferWiseMethodInfo'
import WesternMethodInfo from '@/components/withdraws/infos/WesternMethodInfo'
import { WithdrawActions } from '@/store'
import PaymentWarning from "../custom/warnings/PaymentWarning";

const MethodTypes = ['bank', 'paxum', 'cosmo', 'e_pay_services', 'skrill', 'paypal', 'western', 'btc']
const ValidMethodTypes = ['bank', 'paxum', 'paypal', 'btc', 'cosmo']
const MethodForms = {
  bank: BankMethodForm,
  btc: BtcMethodForm,
  cosmo: CosmoMethodForm,
  e_pay_services: EPayMethodForm,
  paxum: PaxumMethodForm,
  paypal: PaypalMethodForm,
  skrill: SkrillMethodForm,
  transfer_wise: TransferWiseMethodForm,
  western: WesternMethodForm
}
const MethodInfos = {
  bank: BankMethodInfo,
  btc: BtcMethodInfo,
  cosmo: CosmoMethodInfo,
  e_pay_services: EPayMethodInfo,
  paxum: PaxumMethodInfo,
  paypal: PaypalMethodInfo,
  skrill: SkrillMethodInfo,
  transfer_wise: TransferWiseMethodInfo,
  western: WesternMethodInfo
}

export default {
  name: 'WithdrawMethodDialog',
  components: {PaymentWarning},
  mixins: [dialog],
  props: {
    value: { type: Boolean },
    dark: {
      type: Boolean,
      default: null
    },
    isEdit: {
      type: Boolean,
      defaults: false
    },
    method: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      methods: MethodTypes,
      selectedMethod: '',
      validForm: false,
      file: null,
      validFormDialog: false,
      withdrawMethodApproved: false,
      showWarning: false
    }
  },
  computed: {
    ...mapState('withdraws', ['withdrawMethods', 'withdrawData']),
    fileRequired () {
      if (this.withdrawData.business !== 'first_person') {
        return [this.$vRequired]
      }
      return []
    },
    isUploadedAgreement () {
      return this.file && this.file.uploaded
    },
    availableMethods () {
      return ValidMethodTypes
        .map(method => ({
          text: this.$t(`withdraws.withdraw_methods.types.${method}`),
          value: method
        }))
    },
    methodForm () {
      return MethodForms[this.selectedMethod]
    },
    methodInfo () {
      return MethodInfos[this.selectedMethod]
    },
    isBank () {
      return this.method.type === 'bank'
    },
    validFromSave () {
      if (this.isEdit) {
        if (!this.isBank) {
          return this.validForm
        } else {
          return this.validForm && this.method.withdrawMethodUrl
        }
      } else {
        return this.validFormDialog && this.validForm && this.file && !this.file.loading
      }
    },
    requiredFile () {
      /* if (this.withdrawData.business === 'first_person') {
        return false
      } */
      const requiredFile = ['bank']
      return requiredFile.findIndex(type =>
        type === this.selectedMethod
      ) !== -1
    }
  },
  watch: {
    value (val) {
      this.dialog = val
    },
    dialog (val) {
      this.$emit('input', val)
    },
    selectedMethod () {
      this.file = {}
    }
  },
  methods: {
    onSubmit () {
      /*if (this.selectedMethod === 'paypal') {
        this.showWarning = true
        return
      }*/
      this.confirmSubmit()
    },
    confirmSubmit () {
      this.showWarning = false
      this.methodAttrs.withdrawMethodPath = this.file.location
      if (this.isEdit && !this.file.location) {
        this.methodAttrs.withdrawMethodPath = this.method.withdrawMethodPath
      }
      this.$store.dispatch(WithdrawActions.CreateMethod, this.methodAttrs)
      this.hideDialog()
    },
    saveValidForm (validForm) {
      this.validForm = validForm
    },
    saveMethodAttrs (attrs) {
      this.methodAttrs = attrs
    }
  },
  mounted () {
    if (this.method) {
      this.selectedMethod = this.method.type
      if (this.isEdit) {
        this.withdrawMethodApproved = true
      }
    }
  }
}
</script>
