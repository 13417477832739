<template>
  <v-card flat tile>
    <v-card-text>
      <h2 class="dark-title">{{ $t("widgets.recommendations.title") }}</h2>
    </v-card-text>
    <template v-if="!horizontal">
      <template v-for="user in users">
        <UserCard :key="user.username"
          horizontal
          :user="user" />
      </template>
      <div class="discovery-card" v-if="!loading">
        <c-btn
               x-small
               css="my-4"
               :color="showMoreColor"
               :to="{ name: 'stars_discover' }">{{ $t('actions.show_more') }}
        </c-btn>
      </div>
    </template>
    <template v-else>
      <Slider :items="users">
        <template #default="{ item }">
          <UserCard :user="item" vertical />
        </template>
      </Slider>
    </template>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { UserActions } from '@/store'
import UserCard from '@/components/users/UserCard'
import Slider from '@/components/widgets/Slider'
import config from '@/project'

export default {
  name: 'InfluencerRecommendations',
  components: { UserCard, Slider },
  props: {
    empty: Boolean,
    horizontal: { type: Boolean, default: false }
  },
  data () {
    return {
      users: [],
      loading: false
    }
  },
  computed: {
    ...mapState('preferences', ['darkTheme']),
    titleText () {
      return this.empty
        ? this.$t('users.discovery.empty_title')
        : this.$t('users.discovery.title')
    },
    descriptionText () {
      return this.empty
        ? this.$t('users.discovery.empty_description')
        : this.$t('users.discovery.description')
    },
    showMoreColor () {
      return this.darkTheme ? 'secondary' : 'primary'
    }
  },

  // TODO Move search to mixin
  methods: {
    searchQuery () {
      return {
        role: 'influencer',
        order: 'online:desc,featured:desc,postCount:desc',
        ...this.$store.getters['preferences/selected_tags_ids'],
        following: false,
        subscribed: false,
        includeOnline: true,
        includeSelf: false,
        includeTags: true,
        // DARKFANS
        darkfans: config.project === 'darkfans',
        perPage: 5
      }
    },

    async fetch (withTags = true) {
      const query = this.searchQuery()
      if (!withTags) {
        delete query.ageIds
        delete query.categoryIds
        delete query.genderIds
      }
      const { data, error } = await this.$store.dispatch(UserActions.Search, query)
      if (!error) {
        this.users = data.data
        return data.data
      }
      return []
    },

    async load () {
      this.loading = true
      const data = await this.fetch()
      if (!data.length) {
        await this.fetch(false)
      }
      this.loading = false
    }
  },
  created () {
    this.load()
  }
}
</script>
<style lang="scss" scoped>
  .user-row {
    padding: 2px;
    border-radius: 5px;
  }

  .discovery-card {
    width: 400px;
    text-align: center;
    margin: 16px auto;
  }

  .dark {
    background-color: rgba(0, 0, 0, 0.51) !important;

    .dark-title {
      color: #df3856 !important;
    }

    .dark-content {
      background-color: black !important;
      color: #df3856 !important;
    }
  }
  .user-recommendations {
    width: 30%;
  }
</style>
