<template >
  <section>
    <v-sheet v-if="showCookies"
             class="banner pt-0" :class="{expanded: showExpandedCookies}">
      <div class="row">
        <div :class="`col-${isMobile ? 12 : 8} center`">
          <p v-html="$t(`cookies.content`)"></p>
        </div>
        <div :class="`col-${isMobile ? 12 : 4} center`">
          <div class="row information">
            <v-btn @click="configure" class="mt-2 acceptBtn">
              {{ $t(`actions.configure`)}}
            </v-btn>
            <v-btn color="error" @click="reject" class="mt-2 rejectBtn">
              {{ $t(`actions.reject`)}}
            </v-btn>
            <v-btn color="success" @click="accept" class="mt-2 acceptBtn">
              {{ $t(`actions.ok`)}}
            </v-btn>
          </div>
        </div>
      </div>
    </v-sheet>
    <c-dialog v-model="showExpandedCookies" :key="!showExpandedCookies" :actions="false" :fullscreen="isMobile" maxWidth="1100" width="1100">
      <div class="col-12">
        <p v-html="$t('cookies.title_2')"></p>
      </div>
      <div class="col-12" style="font-size: 14px;">
        {{ $t('cookies.desc_1')}}
        <br>
        {{ $t('cookies.desc_2')}}
        <br>
        {{ $t('cookies.desc_3')}}
        <br>
        {{ $t('cookies.desc_4')}}
      </div>
      <div class="col-12">
        <span class="titulo">{{ $t('cookies.owner.title')}}</span>
        <br>
        {{ $t('cookies.owner.desc')}}
      </div>

      <div class="row cookieSelected mt-1" v-if="!isMobile">
        <div class="col-9">
          <div class="row">
            <div class="col-4">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.owner.session.title`)"></div>
            </div>
            <div class="col-3">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.owner.session.desc`)"></div>
            </div>
            <div class="col-5">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.owner.session.need`)"></div>
            </div>
          </div>
        </div>
        <div class="col-3 center">
          <div class="row cookie">
            <v-col cols="6">
              <v-btn :color="cookieSession ? 'success' : 'error'" @click="cookieSession = true" class="acceptBtn">
                <span style="font-size: 10px !important">{{ $t(`actions.ok`)}}</span>
              </v-btn>
            </v-col>
          </div>
        </div>
      </div>

      <div class="row cookieSelected mt-1" :class="{dark: darkTheme}" v-else>
        <div class="col-12 ma-0 mt-4">
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.owner.session.title`)"></div>
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.owner.session.desc`)"></div>
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.owner.session.need`)"></div>
        </div>
        <div class="col-4 cookie center ml-auto">
          <v-btn :color="cookieSession ? 'success' : 'error'" @click="cookieSession = true" class="acceptBtn">
            <span style="font-size: 10px !important">{{ $t(`actions.ok`)}}</span>
          </v-btn>
        </div>
      </div>

      <div class="row cookieSelected mt-1" :class="{dark: darkTheme}" v-if="!isMobile">
        <div class="col-9">
          <div class="row">
            <div class="col-4">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.owner.preferences.title`)"></div>
            </div>
            <div class="col-3">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.owner.preferences.desc`)"></div>
            </div>
            <div class="col-5">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.owner.preferences.need`)"></div>
            </div>
          </div>
        </div>
        <div class="col-3 center">
          <div class="row cookie">
            <v-col cols="6">
              <v-btn :color="cookieSession ? 'success' : 'error'" @click="cookiePref = true" class="acceptBtn">
                <span style="font-size: 10px !important">{{ $t(`actions.ok`)}}</span>
              </v-btn>
            </v-col>
          </div>
        </div>
      </div>

      <div class="row cookieSelected mt-1" :class="{dark: darkTheme}" v-else>
        <div class="col-12 ma-0 mt-4">
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.owner.preferences.title`)"></div>
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.owner.preferences.desc`)"></div>
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.owner.preferences.need`)"></div>
        </div>
        <div class="col-4 cookie center ml-auto">
          <v-btn :color="cookiePref ? 'success' : 'error'" @click="cookiePref = true" class="acceptBtn">
            <span style="font-size: 10px !important">{{ $t(`actions.ok`)}}</span>
          </v-btn>
        </div>
      </div>

      <div class="col-12">
        <span class="titulo">{{ $t('cookies.third.title')}}</span>
      </div>


      <div class="row cookieSelected mt-1" :class="{dark: darkTheme}" v-if="!isMobile">
        <div class="col-9">
          <div class="row">
            <div class="col-4">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.twitter.title`)"></div>
            </div>
            <div class="col-3">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.twitter.desc`)"></div>
            </div>
            <div class="col-5">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.twitter.need`)"></div>
            </div>
          </div>
        </div>
        <div class="col-3 center">
          <div class="row cookie">
            <v-col cols="6">
              <v-btn :color="cookieTwitter ? 'success' : 'gray'" @click="cookieTwitter = true" class="acceptBtn">
                <span style="font-size: 10px !important">{{ $t(`actions.ok`)}}</span>
              </v-btn>
            </v-col>
          </div>
        </div>
      </div>

      <div class="row cookieSelected mt-1" :class="{dark: darkTheme}" v-else>
        <div class="col-12 ma-0 mt-4">
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.twitter.title`)"></div>
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.twitter.desc`)"></div>
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.twitter.need`)"></div>
        </div>
        <div class="col-4 cookie center ml-auto">
          <v-btn color="success" class="acceptBtn">
            <span style="font-size: 10px !important">{{ $t(`actions.ok`)}}</span>
          </v-btn>
        </div>
      </div>


      <div class="row cookieSelected mt-1" :class="{dark: darkTheme}" v-if="!isMobile">
        <div class="col-9">
          <div class="row">
            <div class="col-4">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.analytics.title`)"></div>
            </div>
            <div class="col-3">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.analytics.desc`)"></div>
            </div>
            <div class="col-5">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.analytics.need`)"></div>
            </div>
          </div>
        </div>
        <div class="col-3 center">
          <div class="row cookie">
            <v-col cols="6">
              <v-btn :color="!cookieAnalytics ? 'error' : 'gray'" @click="cookieAnalytics = false" class="acceptBtn mr-1">
                <span style="font-size: 10px !important">{{ $t(`actions.reject`)}}</span>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn :color="cookieAnalytics ? 'success' : 'gray'" @click="cookieAnalytics = true" class="acceptBtn">
                <span style="font-size: 10px !important">{{ $t(`actions.ok`)}}</span>
              </v-btn>
            </v-col>
          </div>
        </div>
      </div>

      <div class="row cookieSelected mt-1" :class="{dark: darkTheme}" v-else>
        <div class="col-12 ma-0 mt-4">
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.analytics.title`)"></div>
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.analytics.desc`)"></div>
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.analytics.need`)"></div>
        </div>
        <div class="col-6 cookie center ml-auto">
          <v-btn :color="!cookieAnalytics ? 'error' : 'gray'" @click="cookieAnalytics = false" class="acceptBtn mr-1">
            <span style="font-size: 10px !important">{{ $t(`actions.reject`)}}</span>
          </v-btn>
          <v-btn :color="cookieAnalytics ? 'success' : 'gray'" @click="cookieAnalytics = true" class="acceptBtn">
            <span style="font-size: 10px !important">{{ $t(`actions.ok`)}}</span>
          </v-btn>
        </div>
      </div>


      <div class="row cookieSelected mt-1" :class="{dark: darkTheme}" v-if="!isMobile">
        <div class="col-9">
          <div class="row">
            <div class="col-4">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.facebook.title`)"></div>
            </div>
            <div class="col-3">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.facebook.desc`)"></div>
            </div>
            <div class="col-5">
              <div class="row ml-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.facebook.need`)"></div>
            </div>
          </div>
        </div>
        <div class="col-3 center">
          <div class="row cookie">
            <v-col cols="6">
              <v-btn :color="!cookieMeta ? 'error' : 'gray'" @click="cookieMeta = false" class="acceptBtn mr-1">
                <span style="font-size: 10px !important">{{ $t(`actions.reject`)}}</span>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn :color="cookieMeta ? 'success' : 'gray'" @click="cookieMeta = true" class="acceptBtn">
                <span style="font-size: 10px !important">{{ $t(`actions.ok`)}}</span>
              </v-btn>
            </v-col>
          </div>
        </div>
      </div>

      <div class="row cookieSelected mt-1" :class="{dark: darkTheme}" v-else>
        <div class="col-12 ma-0 mt-4">
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.facebook.title`)"></div>
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.facebook.desc`)"></div>
          <div class="row ml-2 mt-1" :class="{dark: darkTheme}" style="color: black !important;" v-html="$t(`cookies.third.facebook.need`)"></div>
        </div>
        <div class="col-6 cookie center ml-auto">
          <v-btn :color="!cookieMeta ? 'error' : 'gray'" @click="cookieMeta = false" class="acceptBtn mr-1">
            <span style="font-size: 10px !important">{{ $t(`actions.reject`)}}</span>
          </v-btn>
          <v-btn :color="cookieMeta ? 'success' : 'gray'" @click="cookieMeta = true" class="acceptBtn">
            <span style="font-size: 10px !important">{{ $t(`actions.ok`)}}</span>
          </v-btn>
        </div>
      </div>

      <div class="row">
        <div :class="`col-${isMobile ? 12 : 9} center`">
          <p v-html="$t(`cookies.content`)"></p>
        </div>
        <div :class="`col-${isMobile ? 12 : 3} center`">
          <div class="row information">
            <v-btn @click="acceptSelected" color="gray" class="mt-2 acceptBtn">
              <span style="font-size: 10px !important">{{ $t(`actions.ok_selected`)}}</span>
            </v-btn>
            <v-btn color="success" @click="accept" class="mt-2 acceptBtn">
              <span style="font-size: 10px !important">{{ $t(`actions.ok_all`)}}</span>
            </v-btn>
          </div>
        </div>
      </div>
    </c-dialog>
  </section>
</template>
<script>
import cookies from '@/cookies'
import { mapState } from 'vuex'
import { AppActions } from '@/store'
import config from '@/project'

export default {
  name: 'CookiesModal',
  data () {
    return {
      showExpandedCookies: false,
      cookieSession: true,
      cookiePref: true,
      cookieTwitter: true,
      cookieAnalytics: null,
      cookieMeta: null,
      config
    }
  },
  computed: {
    ...mapState('application', ['showCookies', 'isMobile']),
    ...mapState('preferences', ['darkTheme']),
  },
  methods: {
    accept () {
      const ga = `ga-disable-${config.analytics.ga}`
      cookies.set('allow-cookies-v2', true)
      cookies.set('meta-ck', true)
      cookies.set('analytics-ck', true)
      const utms = [];
      if (cookies.get('utm_source')) utms.push(`utm_source=${cookies.get('utm_source')}`)
      if (cookies.get('utm_campaign')) utms.push(`utm_campaign=${cookies.get('utm_campaign')}`)
        if (cookies.get('utm_term')) utms.push(`utm_term=${cookies.get('utm_term')}`)
      if (cookies.get('utm_content')) utms.push(`utm_content=${cookies.get('utm_content')}`)
      if (cookies.get('utm_medium')) utms.push(`utm_medium=${cookies.get('utm_medium')}`)
      let urlParams = ''
      if (utms.length > 0) {
        urlParams = '?'
        const params = utms.join('&')
        urlParams = urlParams + params
      }
      this.cookieAnalytics = true
      this.cookieMeta = true
      window[ga] = true
      this.$store.dispatch(AppActions.UpdateCookies, true)
      window.location.search += urlParams
      this.showExpandedCookies = false
    },
    reject () {
      const ga = `ga-disable-${config.analytics.ga}`
      cookies.set('allow-cookies-v2', true)
      cookies.set('meta-ck', false)
      cookies.set('analytics-ck', false)
      this.cookieAnalytics = false
      this.cookieMeta = false
      window[ga] = false
      this.$store.dispatch(AppActions.UpdateCookies, true)
      this.showExpandedCookies = false
    },
    acceptSelected () {
      cookies.set('allow-cookies-v2', true)
      cookies.set('meta-ck', this.cookieMeta)
      cookies.set('analytics-ck', this.cookieAnalytics)
      const utms = [];
      if (cookies.get('utm_source')) utms.push(`utm_source=${cookies.get('utm_source')}`)
      if (cookies.get('utm_campaign')) utms.push(`utm_campaign=${cookies.get('utm_campaign')}`)
      if (cookies.get('utm_term')) utms.push(`utm_term=${cookies.get('utm_term')}`)
      if (cookies.get('utm_content')) utms.push(`utm_content=${cookies.get('utm_content')}`)
      if (cookies.get('utm_medium')) utms.push(`utm_medium=${cookies.get('utm_medium')}`)
      let urlParams = ''
      if (utms.length > 0) {
        urlParams = '?'
        const params = utms.join('&')
        urlParams = urlParams + params
      }
      const ga = `ga-disable-${config.analytics.ga}`
      window[ga] = this.cookieAnalytics
      this.$store.dispatch(AppActions.UpdateCookies, true)
      this.$router.replace(`${this.$router.currentRoute.fullPath}${urlParams}`)
      this.showExpandedCookies = false
    },
    configure () {
      this.showExpandedCookies = !this.showExpandedCookies
    }
  },
  beforeMount() {
    this.cookieAnalytics = cookies.get('analytics-ck') ? cookies.get('analytics-ck') === 'true' : true
    this.cookieMeta = cookies.get('meta-ck') ? cookies.get('meta-ck') === 'true' : true
  }
}
</script>

<style lang="scss" scoped>

  .col-12 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .banner {
    min-width: 350px;
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 120px;
    padding: 15px;
    background-color: #d9d9d9;
    color: black;
    z-index: 100;
  }

  span.titulo {
    font-weight: bold;
  }

  .information {
    color: black;
  }

  .acceptBtn {
    margin: 15px auto 0 auto;
    width: 180px;
  }

  .cookies {
    background-color: #df3856;
    width: fit-content;
    height: 10px;
    margin-left: auto;
  }

  .cookiesInfo {
    font-size: 14px;
  }

  .cookie {
    .acceptBtn {
      width: 80px;
      height: 30px !important;
      font-size: 10px;
      margin: initial;
    }
  }

  .cookieSelected {
    background-color: #e1e1e1 !important;
    color: black !important;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: stretch;
    &.dark {
      background-color: #3a3a3a;
    }
    &.mobile {
      height: 70px !important;
    }
  }

  @media (max-width: 763px) {
    .banner {
      height: 220px;
    }
  }

  @media (max-width: 702px) {
    .banner {
      height: 220px;
    }
  }

  @media (max-width: 601px) {
    .banner {
      height: 240px;
    }
  }

  @media (max-width: 501px) {
    .banner {
      height: 260px;
    }
  }

  @media (max-width: 451px) {
    .banner {
      height: 290px;
    }
  }

  @media (max-width: 401px) {
    .banner {
      height: 305px;
    }
  }

  @media (max-width: 351px) {
    .banner {
      height: 325px;
    }
  }

  .row.ml-1.dark {
    color: black !important;
  }

</style>
