<template>
  <v-text-field
    v-bind="$attrs"
    v-model="internalValue"
    :append-icon="currencySymbol"
    :hint="computedHint"
    :rules="allRules"
    :placeholder="placeholder"
    :dark="isDark"
    persistent-hint
    @input="updateValue"
    @blur="maskValue">
    <slot />
  </v-text-field>
</template>

<script>
import { mapState } from 'vuex'
const DecimalRegEx = /^\s*-?(\d+(,\d{2})?|,\d{2})\s*$/

const unmask = (value) => {
  return value ? parseInt(parseFloat(value.replace(',', '.')) * 100) : null
}

const mask = (value) => {
  let masked = value
  const parsed = parseInt(masked) / 100
  if (!isNaN(parsed)) {
    masked = parsed.toFixed(2).replace('.', ',')
  }

  return masked
}

import project from '@/project'

export default {
  name: 'CurrencyInput',
  props: {
    value: [String, Number],
    rules: { type: Array, default: () => [] },
    hint: { type: String },
    currency: String,
    minValue: { type: [String, Number], default: null },
    maxValue: { type: [String, Number], default: 42000 },
    dark: { type: Boolean, default: null },
    placeholder: String,
    signup: Boolean
  },
  data () {
    return {
      internalValue: '',
      globalTimeout: null,
      project
    }
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    computedHint() {
      return this.hint || this.$t('types.currency.format_hint')
    },
    currencySymbol () {
      const currency = this.currency || this.currentUser.billing.currency
      return currency === 'USD' ? 'monetization_on' : 'euro_symbol'
    },
    allRules () {
      return [...this.rules, this.isValidFormat, this.greaterThanMin, this.lowerThanMax]
    },
    isDark () {
      return !project.project.includes('loverfans')
    }
  },
  watch: {
    value(value) {
      if (value === unmask(this.internalValue)) return
      this.internalValue = mask(value)
    }
  },
  methods: {
    isValidFormat (value) {
      return DecimalRegEx.test(value) || this.hint || this.$t('types.currency.format_hint')
    },
    greaterThanMin (value) {
      return this.minValue === null || unmask(value) >= parseInt(this.minValue) || this.$t('errors.amount_too_low', { amount: this.$currency(this.minValue, this.currency || this.currentUser.billing.currency) })
    },
    lowerThanMax (value) {
      return this.maxValue === null || unmask(value) <= parseInt(this.maxValue) || this.$t('errors.no_max_amount',{ amount: this.$currency(this.maxValue, this.currency || this.currentUser.billing.currency) })
    },
    updateValue (value) {
      if (!DecimalRegEx.test(value)) return
      this.$emit('input', unmask(value))
    },
    maskValue () {
      if (!DecimalRegEx.test(this.internalValue)) return
      this.internalValue = mask(unmask(this.internalValue))
    }
  },
  mounted () {
    //this.internalValue = mask(this.value)
  }
}

</script>
