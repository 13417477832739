<template>
  <section style="position: relative">
    <div class="bottom-nav" v-if="isMobile && !drawer">
      <div class="menu-mobile justify-around items-center">
        <c-btn text rounded
               :to="{ name: 'home' }"
               transform="none"
               active-class="none"
               color=""
               hover-color="">
          <img src="/images/icons/home.svg" style="width: 1.5rem; opacity: 0.4;"/>
        </c-btn>
        <c-btn text rounded
               :to="{ name: 'notifications' }"
               active-class="primary--text"
               transform="none"
               color=""
               hover-color="primary">
          <img src="/images/icons/notifications.svg" style="width: 1.5rem; opacity: 0.4;"/>
          <div class="circulo" v-if="hasNotifications">
            <div class="circulo-white">
            </div>
          </div>
        </c-btn>
        <c-btn text rounded
               @click="checkRole()"
               transform="none"
               color="primary"
               hover-color="primary">
          <svg xmlns="http://www.w3.org/2000/svg" style="width: 2.5rem" viewBox="0 0 16 16" >
            <path data-name="Trazado 782" d="M11.482 7.385H8.616V4.52a.616.616 0 0 0-1.231 0v2.866H4.519A.59.59 0 0 0 3.904 8a.6.6 0 0 0 .616.616h2.866v2.866a.6.6 0 0 0 .613.619.613.613 0 0 0 .616-.616V8.616h2.866a.616.616 0 0 0 0-1.231Z" :fill="getColor"/>
            <path data-name="Trazado 783" d="M8 1.077a6.921 6.921 0 1 1-4.9 2.027A6.92 6.92 0 0 1 8 1.077M8 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8"  :fill="getColor"/>
          </svg>
<!--          <img src="/images/icons/publicar.svg" style="width: 1.75rem"/>-->
        </c-btn>
        <c-btn text rounded
               :href="chatHref"
               :to="chatRoute"
               active-class="primary--text"
               transform="none"
               color=""
               hover-color="primary">
          <img src="/images/icons/menu-message.svg" style="width: 1.5rem; opacity: 0.4;"/>
          <div class="circulo chat" v-if="true">
            <div class="circulo-white">
            </div>
          </div>
        </c-btn>

        <c-btn text rounded
               active-class="primary--text"
               transform="none"
               color=""
               @click="drawer = !drawer"
               hover-color="primary">
          <v-avatar size="36">
            <c-img placeholder="false" height="36" :src="currentUser.avatarS3Route"></c-img>
          </v-avatar>
        </c-btn>
<!--        <c-btn text rounded-->
<!--               active-class="primary&#45;&#45;text"-->
<!--               transform="none"-->
<!--               color=""-->
<!--               hover-color="primary">-->
<!--          <v-avatar size="36">-->
<!--            <c-img placeholder="false" height="36" :src="currentUser.avatarS3Route"></c-img>-->
<!--          </v-avatar>-->
<!--        </c-btn>-->
        <!--      <Icon icon="home" size="7"  :class="ui.menu" @click="selected(0)"/>-->
        <!--      <Icon icon="notifications" size="7" :class="ui.menu" @click="selected(1)"/>-->
        <!--      <publish-button icon>-->
        <!--        <template #btn>-->
        <!--          <Icon icon="publicar" size="12" :class="ui.main" @click="selected(2)"/>-->
        <!--        </template>-->
        <!--      </publish-button>-->
        <!--      <Icon icon="menu-message" size="7" :class="ui.menu" @click="selected(3)"/>-->
        <!--      <user-avatar size="2xs" class="mt-2" :user="currentUser" noActions @click="toggleMenu"/>-->
      </div>

      <!--<v-navigation-drawer
        v-if="drawer && $route.fullPath !== '/month_challenge'"
        v-model="drawer"
        style="height: 100%;"
        app
      >
        <AppNavigation :maintance="false" />

      </v-navigation-drawer>-->
      <!--<app-bar-menu bottom left></app-bar-menu>-->
    </div>
    <PublicationDialog
      v-model="dialog"
      :type.sync="selectedType"/>
    <v-navigation-drawer
      v-if="drawer && $route.fullPath !== '/month_challenge' && isMobile"
      v-model="drawer"
      :dark="darkTheme"
      style="height: 100%;"
      app
      right
    >
      <AppNavigation :maintance="false" />

    </v-navigation-drawer>
  </section>

</template>

<script>

import { mapState } from 'vuex'
import config from '@/project'
import PublicationDialog from '@/components/custom/PublicationDialog'
import dialog from '@/components/mixins/dialog'
import AppNavigation from '@/components/layouts/AppNavigation.vue'

export default {
  name: 'BottomNav',
  mixins: [dialog],
  components: {
    AppNavigation,
    PublicationDialog
  },
  data () {
    return {
      selectedType: 'post',
      drawer: false,
      menu: false
    }
  },
  computed: {
    ...mapState('application', ['isMobile', 'hasNotifications', 'countUnreadMessages']),
    ...mapState('profile', ['currentUser', 'isInfluencer']),
    ...mapState('session', ['authenticated']),
    ...mapState('preferences', ['darkTheme']),
    notificationsTooltip () {
      return this.$t('application.menu.notifications')
    },
    getColor () {
      return config.primary_color
    },
    chatHref () {
      if (!this.mustRedirect) return undefined
      return `https://darkfans.com/messages?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`
    },
    chatRoute () {
      if (this.mustRedirect) return undefined
      return { name: 'messages' }
    },
    unreadMessages () {
      return this.countUnreadMessages !== ''
    }
  },
  methods: {

    checkRole () {
      if (!this.authenticated) {
        sessionStorage.setItem('origin', this.$router.currentRoute.fullPath)
        this.$router.push({ name: 'login' })
      } else if (!this.isInfluencer) {
        this.$router.push({ name: 'influencer', params: { username: this.currentUser.username } })
      } else {
        /* if (!this.currentUser.signedDocument) {
          this.showModal2Contract = true
          return
        } */
        this.showDialog()
      }
    }
  }
}
</script>

<style scoped lang="scss">

.bottom-nav {
  position: fixed;
  bottom: 0px;
  --tw-bg-opacity: 1;
  width: 100%;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  height: 48px;
  border-radius: 24px 24px 0px 0px;
  border-width: thin !important;
  border-style: solid !important;
  border-color: #0000001f !important;
}

.menu-mobile{
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: 5px;
}

.c-bottom {
  bottom: -2px;
}

.scale-up-bottom-right {
  animation: scale-up-bottom-right 0.4s;
}

@keyframes scale-up-bottom-right {
  0% {
    transform: scale(.5);
    transform-origin: right bottom
  }
  100% {
    transform: scale(1);
    transform-origin: right bottom
  }
}

.scale-up-right {
  animation: scale-up-right 0.4s;
}

@keyframes scale-up-right {
  0% {
    transform: scale(.5);
    transform-origin: right center
  }
  100% {
    transform: scale(1);
    transform-origin: right center
  }
}

.modal{
  background-color: #555555 !important;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.v-btn::before { background-color: transparent;}
</style>
