<template>
  <section>
    <c-btn
      v-if="!isSubscribed"
      v-bind="$attrs"
      :color="color"
      :hover-color="hoverColor"
      :tooltip="tooltipText"
      @click.stop.prevent="emitAction"
    >
      <template slot="hover">
        <span v-if="showCancel">
          <v-icon :dark="darkTheme" v-bind="$attrs">delete_outline</v-icon>
          {{ $t("actions.cancel") }}
        </span>
        <span v-else-if="isSubscribed">
          {{ $t("actions.already_subscribed") }}
        </span>
      </template>
      <div v-if="oldPrice" class="old-price">
        <p>{{ subscriptionBasePrice }}</p>
      </div>
      <span :style="getColor"> {{ buttonText }}</span>
      <slot />
    </c-btn>
    <v-chip label small color="white" text-color="black" v-else>
      {{ buttonText }}</v-chip
    >
    <CancelSubscriptionModal v-model="dialog" :username="resource.username" />
  </section>
</template>
<script>
import Utils from '@/utils'
import { mapState } from 'vuex'
import dialog from '@/components/mixins/dialog'
import CancelSubscriptionModal from '@/components/users/CancelSubscriptionModal'
import config from '@/project'
import {UserActions} from "@/store";

export default {
  name: 'SubscribeButton',
  mixins: [dialog],
  components: { CancelSubscriptionModal },

  props: {
    resource: Object,
    color: String,
    hoverColor: String,
    label: String,
    isProfile: { type: Boolean, default: true },
    oldPrice: { type: Boolean, default: false },
    campaign: { type: Boolean, default: false }
  },
  computed: {
    ...mapState('preferences', ['darkTheme']),
    ...mapState('profile', ['currentUser']),
    getColor () {
      return { color: this.campaign ? 'black !important' : '' }
    },
    buttonText () {
      return this.isCanceled
        ? this.$t('subscriptions.canceled')
        : this.label || (this.resource.subscribed && !this.isProrrogated)
          ? this.$t('users.subscribed')
          : this.$t('users.subscribe', { price: this.subscriptionPrice })
    },
    subscriptionPrice () {
      const { minSubscriptionPrice, currency } = this.resource
      return this.$currency(!this.isSpecialOfferActive ? minSubscriptionPrice : (minSubscriptionPrice * (100 - config.specialOffer.discount) / 100), currency)
    },
    subscriptionBasePrice () {
      const monthSubscription = this.resource.subscriptionOffers.filter(
        s => s.type === 'month'
      )
      const { currency } = this.resource
      return this.$currency(monthSubscription[0].basePrice, currency)
    },
    showCancel () {
      return (
        this.resource.subscribed &&
        !this.resource.subscriptionEnd &&
        !this.isProfile
      )
    },
    isSubscribed () {
      return this.resource.subscribed && this.isProfile && !this.isProrrogated
    },
    isCanceled () {
      return this.resource.subscribed && !!this.resource.subscriptionEnd
    },
    endsToday () {
      const endsIn = Utils.formatDate(this.resource.subscriptionEnd)
      const today = Utils.formatDate(new Date())
      return endsIn === today
    },
    disableButton () {
      return this.isProfile && this.isSubscribed && !this.isCanceled
    },
    isProrrogated () {
      const prorrogated = this.currentUser.prorrogatedSubscriptions || []
      if (!prorrogated.length) return false
      return !!prorrogated.find(
        p => p.influencer.toLowerCase() === this.resource.username.toLowerCase()
      )
    },
    tooltipText () {
      if (this.isCanceled) {
        const timeago = this.endsToday
          ? this.$t('words.today')
          : this.$i18nTimeago(this.resource.subscriptionEnd)
        return `${this.$t('words.finish_in')} ${timeago}`
      }
      return ''
    },
    isSpecialOfferActive () {
      const today = new Date()
      return config.specialOffer.active && config.specialOffer.startDate < today && config.specialOffer.endDate > today && config.specialOffer.discount > 0
    }
  },

  methods: {
    async emitAction () {
      if (this.isCanceled) {
        return
      }

      if (!this.resource.subscribed || this.isProrrogated) {
        this.$emit('click', true)
      } else if (!this.isProfile) {
        this.showDialog()
      }
    },
    closedCancel (value) {
      this.resource = this.$store.getters['users/getUser'](
        this.resource.username
      )
      this.showCancelDialog = false
    }
  },
  beforeMount() {
    if (this.subscriptionBasePrice === 0 || !this.subscriptionBasePrice) {
      this.resource = this.$store.dispatch(UserActions.Fetch, {
        username: this.selectedChat.receiver.username,
        force: true
      })
    }
  }
}
</script>
<style lang="scss">
.old-price {
  color: red;
  height: 2px;
  transform: matrix(0.97, 0.26, -0.26, 0.97, 0, 0);
  background: #f63535 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 50px;
  p {
    position: relative;
    bottom: 8px;
    text-align: center;
    font: normal normal medium 16px/24px Montserrat;
    letter-spacing: 0px;
    color: #f40303;
    transform: rotate(-12deg);
  }
}
</style>
