<template>
  <div class="d-flex flex-grow-1 justify-space-around">
    <TipButton icon
      :user="post.author || {}"
      :disabled="!authenticated || isAuthor || post.blockedCountry" />
    <c-btn icon
      :disabled="post.blockedCountry || not_validated"
      :color="likeColorClass"
      hover-color="pink darken-1"
      :tooltip="$t('actions.like')"
      @click.stop.prevent="toggleLike">
      <v-icon v-if="post.liked">favorite</v-icon>
      <v-icon v-else>favorite_border</v-icon>
      <span v-if="post.likesCount" class="ml-1">{{ post.likesCount }}</span>
    </c-btn>
    <c-btn text
      :disabled="!post.visible"
      color=""
      hover-color="blue darken-1"
      active-class="none"
      :tooltip="$t('actions.comment')"
      @click.stop.prevent="commentsClicked">
      <v-icon>chat_bubble_outline</v-icon>
      <span v-if="post.commentsCount" class="ml-1">{{ post.commentsCount }}</span>
    </c-btn>
    <c-btn icon
      :disabled="post.blockedCountry"
      :color="sharedColorClass"
      hover-color="indigo darken-1"
      :tooltip="$t('actions.repost', {count: post.sharesCount} )"
      @click.stop.prevent="toggleShare">
      <v-icon>cached</v-icon>
      <span v-if="post.sharesCount" class="ml-1">{{ post.sharesCount }}</span>
    </c-btn>
    <c-btn icon
      :disabled="post.blockedCountry"
      :color="saveColorClass"
      hover-color="deep-purple darken-1"
      :tooltip="$t('actions.favorite')"
      @click.stop.prevent="toggleSave">
      <v-icon v-if="post.saved">bookmark</v-icon>
      <v-icon v-else>bookmark_border</v-icon>
    </c-btn>
    <ShareButton
      :text="$t('posts.share_text')"
      :url="url" />
    <c-dialog persistent
              width="450"
              v-model="dialog"
              @input="close"
              @close="close">
      <v-row align="center">
        <v-col class="grow">{{ $t('email_validation.title') }}</v-col>
        <v-col class="shrink">
          <c-btn outlined
                 small color="blue"
                 @click.stop="requestEmail">
            {{ $t("actions.request_email") }}
          </c-btn>
        </v-col>
      </v-row>
    </c-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { PostActions } from '@/store'
import TipButton from '@/components/tips/TipButton'
import dialog from '@/components/mixins/dialog'
import AccountApi from '@/api/AccountApi'
import config from '@/project'

export default {
  name: 'PostActions',
  mixins: [dialog],
  components: { TipButton },
  props: {
    post: Object
  },
  computed: {
    ...mapState('profile', ['currentUser']),
    ...mapState('session', ['authenticated']),
    likeColorClass () {
      return this.post.liked ? 'pink' : ''
    },
    saveColorClass () {
      return this.post.saved ? 'deep-purple' : ''
    },
    sharedColorClass () {
      return this.post.shared ? 'blue' : ''
    },
    isAuthor () {
      return this.post.username === this.currentUser.username
    },
    url () {
      const origin = window.location.origin
      return `${origin}/posts/${this.post.id}`
    },
    mustRedirect () {
      return config.payments.darkfans_redirect && !this.$route.path.includes('/posts/') && this.currentUser.username !== this.post.username && (this.post.acquired || this.post.author.subscribed || this.post.visibility === 'open')
    },
    not_validated () {
      return !this.currentUser.emailValidated
    }
  },
  methods: {
    commentsClicked () {
      if (this.mustRedirect) {
        window.open(`https://darkfans.com/posts/${this.post.id}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`, '_blank')
        return
      }
      this.$router.push({ name: 'post', params: { uuid: this.post.id } })
      this.$emit('click:comments', true)
    },
    eventEnded (today) {
      // LOS MESES SON DE 0 - 11)
      var eventEnded = config.campaign.ended
      return eventEnded - today < 0
    },
    toggleLike () {
      if (!this.currentUser.emailValidated) {
        return
      }
      if (this.post.promotionSelected) {
        const today = new Date()
        if (!this.authenticated) {
          this.dialog = true
        } else if (!this.eventEnded(today)) {
          this.$store.dispatch(PostActions.ToggleLike, this.post.id)
        }
      } else {
        this.$store.dispatch(PostActions.ToggleLike, this.post.id)
      }
    },
    toggleSave () {
      this.$store.dispatch(PostActions.ToggleSave, this.post.id)
    },
    async toggleShare () {
      const { data } = await this.$store.dispatch(PostActions.ToggleShare, this.post.id)
      if (data.shared) {
        this.post.sharesCount += 1
      } else {
        this.post.sharesCount -= 1
      }
    },
    requestEmail () {
      AccountApi.requestValidation()
    },
    close () {
      this.dialog = false
    }
  }
}
</script>
<style scoped>
.brand-icon {
  height: 35px;
  width: 35px;
}
</style>
