<template>
  <section class="app-fullpage">
    <v-row no-gutters>
      <v-col cols="12" md="8">
        <BackRow class="border-bottom" />
        <DummyLoader css="pa-4"
          page
          cols="1"
          itemCount="1"
          :loading="isLoading">
          <PostPage
            :post="post"
            @switch-comments="switchComments" />
        </DummyLoader>
      </v-col>
      <v-col cols="12" md="4" v-if="!isMobile || (isMobile && showComments)">
        <CommentList
          :commentable="post.visible"
          :class="dynamicClasses"
          :uuid="uuid"
          :author="post.username"/>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { mapState } from 'vuex'
import { PostActions, PreferenceActions } from '@/store'
import PostPage from '@/components/posts/PostPage'
import CommentList from '@/components/comments/CommentList'
import cookies from '@/cookies'
import config from '@/project'

export default {
  name: 'PostView',
  components: { PostPage, CommentList },
  props: {
    uuid: [String, Number]
  },
  metaInfo () {
    return {
      title: this.post.title || this.$t('meta.posts.title', { username: this.username }),
      meta: [
        { vmid: 'description', name: 'description', content: this.post.content },
        { property: 'og:title', content: this.post.title || this.$t('meta.posts.title', { username: this.username }) },
        { property: 'og:site_name', content: config.locales.site },
        { property: 'og:description', content: this.post.content },
        { property: 'og:type', content: 'post' },
        { property: 'og:url', content: config.domains.baseUrl + '/posts/' + this.post.id }
      ]
    }
  },
  data () {
    return {
      post: {},
      user: {},
      isLoading: false,
      showComments: false
    }
  },
  computed: {
    ...mapState('application', ['isMobile', 'tags']),
    ...mapState('profile', ['currentUser']),
    dynamicClasses () {
      return {
        'border-top': this.isMobile,
        'border-left': !this.isMobile
      }
    }
  },
  methods: {
    switchComments () {
      this.showComments = !this.showComments
    },

    async reload () {
      this.isLoading = true
      this.post = await this.$store.dispatch(PostActions.Fetch, { id: this.uuid, force: true })
      if (this.post.errorCode) {
        this.$router.replace({ name: 'home' })
        return
      }
      if ((this.$route.query.utm_source === 'twitter' || this.$route.query.utm_source === 'scatbook') && this.post.author.scatProfile) {
        let catCookies = cookies.get('categories-prefs')
        cookies.set('categories-prefs', catCookies ? catCookies.concat(',scat') : 'scat')
        catCookies = cookies.get('categories-prefs')
        const categories = []
        const tags = this.tags.filter(tag => catCookies.split(',').includes(tag.name)).map(tag => ({ ...tag, text: this.$t(`tags.${tag.name}`) }))
        tags.forEach(tag => categories.push(tag))
        this.$store.dispatch(PreferenceActions.UpdateTagPrefs, true)
        this.$store.dispatch(PreferenceActions.UpdateCategory, categories)
      }
      this.isLoading = false
    }
  },
  beforeMount () {
    this.reload()
  }
}
</script>
