<template>
  <v-hover v-slot:default="{ hover }" class="mb-2 rounded">
    <v-card
      v-bind="$attrs"
      :href="href"
      :to="link"
      :target="mustRedirect ? '_blank' : ''"
      :elevation="hover ? 2 : 0"
    >
      <v-row no-gutters align="center" class="px-1 py-1">
        <v-col cols="7">
          <UserAvatar small :user="user" />
        </v-col>
        <v-col></v-col>
        <v-col cols="auto">
          <btn-video-call small :user="user" class="mt-3 mr-1" />
        </v-col>
        <v-col cols="auto" justify="end">
          <PaymentButton
            v-if="user.subscribable"
            x-small
            inverted
            outlined
            transform="none"
            subscription
            color="secondary"
            hover-color="primary"
            :isProfile="isProfile"
            :hover-outlined="false"
            :resource="user"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>
<script>
import { mapState } from "vuex";
import UserAvatar from "@/components/users/UserAvatar";
import PaymentButton from "@/components/payments/PaymentButton";
import config from "@/project";

export default {
  name: "HorizontalCard",
  components: { UserAvatar, PaymentButton },
  props: {
    user: Object,
    isProfile: Boolean
  },
  computed: {
    ...mapState("profile", ["currentUser"]),
    ...mapState("application", ["isMobile"]),
    isInfluencer() {
      return this.user.role === "influencer";
    },
    mustRedirect() {
      return config.payments.darkfans_redirect && this.user.subscribed;
    },
    link() {
      if (this.mustRedirect) return undefined;
      return { name: "influencer", params: { username: this.user.username } };
    },
    href() {
      if (this.mustRedirect)
        return `https://darkfans.com/${this.user.username}?oa=${this.currentUser.sessionToken}&utm_source=${config.project}`;
      return undefined;
    }
  }
};
</script>
<style lang="scss">
@use 'src/scss/colors';

.user-card {
  position: relative;
  height: 315px;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  &.expand {
    max-width: 80%;
    width: 80%;
  }
  .user-card-background {
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .user-card-wrapper {
    max-width: 100%;
    width: 100%;
    height: 250px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0;
    .user-avatar {
      margin-top: -76px;
    }
    .user-card-actions {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      > * {
        margin-top: 5px;
      }
    }
  }
}

.rank {
  min-height: fit-content;
  div {
    color: white;
    vertical-align: center;
    margin: auto;
  }
}
</style>
