<template>
  <v-list-item>
    <router-link :to="`/${comment.username}`">
      <v-tooltip top>
        <template v-slot:activator = " { on }">
          <v-list-item-avatar  v-on="on" >
            <c-img height="40" :src="comment.avatarS3Route"></c-img>
          </v-list-item-avatar>
        </template>
        <span>{{ comment.username }}</span>
      </v-tooltip>
    </router-link>
    <v-list-item-content >
      <div class="row no-gutters">
        <div class="col-12">
          <div class="rounded bordered secondary-background pa-2 small-text">
            <span class="semibold default-text">{{ comment.username }}.<br></span>
            <component v-bind:is="showComment"/>
          </div>
        </div>
        <div class="col-12 text-right x-small-text">
          <timeago :datetime="comment.date" :locale="$i18n.locale" :auto-update="60"></timeago>
          <v-btn v-if="canDelete" icon @click="$emit('delete')"><v-icon>delete_outline</v-icon></v-btn>
        </div>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import { mapState } from 'vuex'
import project from '@/project'

import { replaceMention } from '@/components/scripts/ReplaceMentions.js'

export default {
  name: 'CommentRow',
  props: {
    comment: Object,
    author: String
  },
  computed: {
    ...mapState('profile', ['currentUser', 'isAdmin', 'isAccountManager']),
    showComment () {
      const comment = replaceMention(this.comment.comment, project.project, this.comment.username)
      return { template: `<section class="default-text">${comment}</section>` }
    },
    canDelete () {
      return this.currentUser.username === this.author || this.currentUser.username === this.comment.username || this.isAdmin || this.isAccountManager
    }
  }
}
</script>
